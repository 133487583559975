html,
body {
  width: 100%;
  background-size: cover;
  background-color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

input[type="text"],
input[type="password"] {
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #01d28e;
  border: 1px solid #01d28e;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #6cf0c2;
}

.button-container {
  display: flex;
  justify-content: center;
}
.input-container {
  color: white;
}

.login-form {
  margin-left: 38%;
  margin-top: 16%;
  width: 20%;
  align-items: center;
  background-color: rgb(85, 85, 85);
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.login-error {
  background-color: rgb(85, 85, 85);
  padding: 2rem;
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-left: 38%;
  margin-top: 18%;
  width: 20%;
  color: red;
  text-align: center;
  margin-top: -40px;
  z-index: -999;
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
  color: white;
}

.title-hide {
  display: none;
  height: 0;
  width: 0;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 10px;
}
