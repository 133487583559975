.invalid__base {
  padding-top: 2%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: black;
}

.invalid__title {
  #{&}__text {
    text-align: center;
    color: white;
    font-weight: 300;
    font-size: 2.5rem;
  }
}
.invalid__image {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../Images/tablet-app-background-green-city.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 8%;
  padding-bottom: 8%;
  border: solid rgba(255, 255, 255, 0.23) 1px;

  #{&}__logo {
    height: 210px;
  }
}
.invalid__footer {
  padding: 30px;
}
